/* global body style */
body {
    background-color: #eeeeee;
    font-family: "Verdana";
    font-size: 10px;
}

.potentialmembers {
    border-collapse: collapse;
    width: 70%;
}

.potentialmembers td,
.potentialmembers th {
    border: 1px solid #ddd;
    padding: 8px;
}

.potentialmembers tr:nth-child(even) {
    background-color: #f2f2f2;
}

.potentialmembers tr:hover {
    background-color: #ddd;
}

.potentialmembers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4caf50;
    color: white;
    position: sticky;
    top: -1px;
} /* The expanding image container */
.idiv {
    position: fixed;
    top: calc(80px + 8px); /* topbar - une marge */
    right: 8px; /* marge de droite, 8 comme tout le reste */
    width: calc(30% - 16px); /* largeur 30% moins les marges aussi */
    height: calc(100% - 80px - 8px - 8px - 8px); /* 100% - le top - 2 marges */
    border: 3px solid #73ad21;
    display: flex;
    justify-content: flex-start; /* Alignement à gauche */
    align-items: flex-start; /* Alignement en haut */
    padding: 8px; /* Ajout de padding pour l'espace intérieur */
    box-sizing: border-box; /* Pour inclure le padding dans la taille */
}

.framecontainer {
    max-width: 100%; /* L'image ne dépasse pas la largeur du conteneur */
    max-height: 100%; /* L'image ne dépasse pas la hauteur du conteneur */
    object-fit: contain; /* L'image s'ajuste pour tenir dans le conteneur sans déformation */
    border: 1px solid #73ad21;
}

.framecontainer img,
.framecontainer div {
    display: block; /* Assure que le contenu est affiché comme bloc pour gérer le alignement */
}
