/* global body style */
body {
    background-color: #eeeeee;
    font-family: "Verdana";
    font-size: 10px;
}

.Quotas {
    font-family: "Verdana";
    font-size: medium;
}
