@import "../../assets/style/mixin";

#hiddenCanvas {
    display: none;
}

.drag-and-drop {
    position: relative;
    display: inline-block;

    .drop-container {
        background: #f2f2f2 no-repeat center;
        width: 230px;
        height: 230px;
        cursor: pointer;
        border: 2px solid red;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.filled {
            border: 0;
            background-size: contain;
        }

        input {
            display: flex;
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: 0;
            z-index: 2;
        }
    }

    button {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        border: none;
        border: 1px solid red;
        border-radius: 50%;
        z-index: 10;
        /*&.remove {
            background: $red url('../../assets/icons/icon-remove.svg') no-repeat center;
            background-size: 1.5rem;
        }
        &.rotate {
            right: calc(0.5rem + 0.5rem + 50px);
            background: white url('../../assets/icons/icon-rotate.svg') no-repeat center;
            background-size: 2rem;
        }
        &:hover {
            opacity: 0.9;
            box-shadow: 0 0 11px rgba(33,33,33,.2);
        }*/
    }
}
