.fader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(211, 211, 211, 0.5);
    text-align: center;
    font-size: large;
    /*padding-top: 25%;*/
    font-weight: bold;
    display: none;
}

.loader:before {
    content: "";
    height: 90px;
    width: 90px;
    margin: -15px auto auto -15px;
    position: absolute;
    top: calc(50% - 45px);
    left: calc(50% - 45px);
    border-width: 8px;
    border-style: solid;
    border-color: #2180c0 #ccc #ccc;
    border-radius: 100%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.loading-text {
    position: absolute;
    top: calc(50% + 90px);
    left: calc(50% - 90px);
    text-align: center;
}
