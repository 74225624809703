/*#region #### STYLE GENERAL ####*/

* {
    /*box-sizing: border-box;*/
    font-family: "Verdana", sans-serif;
    color: black;
}

body {
    overflow-x: hidden;
    padding: 0 !important;
    background-color: #e9e9e9;
    min-height: 100%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.opacity-50 {
    opacity: 0.5;
}
a {
    color: #000000;
}
h1 {
    color: #5a7c02;
    font-weight: 400;
}

h2 {
    font-size: 1.2rem;
    margin-bottom: 0;
    font-weight: 400;
    @media screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
}

div.section {
    color: #8fc505;
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 5px;
}
h4 {
    font-size: 0.9rem;
}

/*#endregion
  */

/*#region  style des zones/Section (rectangle clairs à coins arrondis), gros titre en haut. 
  */
.container {
    border-radius: 5px;
    background-color: #f6f6f6;
    padding: 20px;
    margin: 10px;
}

.formlabel {
    padding: 12px 12px 12px 0;
    display: inline-block;
}

input[type="text"],
textarea {
    font-size: medium;
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    box-sizing: border-box;
}
input[type="date"],
.select {
    font-size: medium;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.inputErr {
    font-size: small;
    color: #962727;
    font-style: italic;
    padding-left: 12px;
    display: none;
}

.inputErrVisible {
    font-size: small;
    color: #962727;
    font-style: italic;
    padding-left: 12px;
}

.tariftotal,
#TOTAL {
    font-size: larger;
    color: #962727;
    font-style: bold;
}
.tarifsuggere,
#tarifsuggere {
    font-size: smaller;
    color: #555555;
    font-style: italic;
}

#divdoublelicence {
    display: none;
}

/*#endregion */

/*the container must be positioned relative:*/

/*#endregion*/

/*#region  #### STYLE POUR LE BOUTON SELECTION DE FICHIER. ####*/

.fileselector {
    /*display: none;*/
    background-color: #ffffff;
    color: #000000;
    padding: 12px;
    /*padding: 12px 20px;*/
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    /*margin-top: 100px;*/
    display: inline-block;

    /* resize: vertical;*/
}

// input[type="submit"] {
.btn-submit {
    background-color: #5a7c02;
    font-size: medium;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: center;
    margin: 10px;

    display: block;
    margin-left: auto;
    margin-right: auto;
    &.disabled {
        background-color: #888888;
        color: #cccccc;
        cursor: not-allowed;
    }
}

.inscription-link {
    background-color: #5a7c02;
    font-size: medium;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: center;
    margin: 10px;

    display: block;
    margin-left: auto;
    margin-right: auto;

    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

/*#endregion*/

/*#region  ##### STYLE POUR LES COLONNES 25% - 75% ET LE RESPONSIVE DESIGN ####*/

.col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
}

.col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;

    &.invalid {
        border-color: red;
    }
}

.col-100 {
    float: left;
    width: 100%;
    margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.error-field {
    float: left;
    font-size: 0.75rem; // 12px
    color: red;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25,
    .col-75,
    input[type="submit"] {
        width: 100%;
        margin-top: 0;
    }
}

/*#endregion*/

/*#region  ##### STYLES POUR LES CHECK BOX  ####*/

/* The container */
.checkboxcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    /*font-size: 22px;*/
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkboxcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkboxcheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkboxcheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* When the checkbox is checked, add background */
.checkboxcontainer input:checked ~ .checkboxcheckmark {
    background-color: #5a7c02;
}

/* Show the checkmark when checked */
.checkboxcontainer input:checked ~ .checkboxcheckmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkboxcontainer .checkboxcheckmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*#endregion*/

/*#region  ##### STYLES POUR LES radio BOX  ####*/

/* The container */
.radiocontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radiocontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.radiocheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ccc;
    border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radiocontainer input:checked ~ .radiocheckmark {
    background-color: #5a7c02;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radiocheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radiocontainer input:checked ~ .radiocheckmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radiocontainer .radiocheckmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

/*#endregion*/
