.navbar {
    height: 80px;
    max-height: 80px !important;
}

.menu {
    position: relative;

    border: 0;
    background: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: "";
        background-size: contain;
        width: 20px;
        height: 20px;
        display: inline-flex;
    }

    &.btn {
        font-weight: 500;
        &:hover {
            box-shadow: none !important;
            cursor: pointer;
            color: #5a7c02;
            text-decoration: underline;
        }
    }
}
